<script setup>
/**
The page title consists of the portion from each individual page and the 'white_label_display_name' response from the settings API.
For the index page, only the second part is used, so the initial part is set as null in the definePageMeta function.
*/
definePageMeta({
  title: null,
})
const {featuredEventsSlidersSplitNumber, upcomingEventsToShow} = useRuntimeConfig().public
const IS_SSR = useNuxtApp().ssrContext

const eventDataStore = useEventDataStore()
const featuredEvents = ref([])
const upcomingEvents = ref([])
const {featuredEventsData, upcomingEventsData} = await eventDataStore.getInitialEvents()
featuredEvents.value = featuredEventsData ? featuredEventsData : []
upcomingEvents.value = upcomingEventsData ? upcomingEventsData : []

const {pending: filtersPending, data: eventFilters} = useFetch(nuApiUrl('events/filters', IS_SSR), {
  method: 'GET',
  lazy: true,
  server: false,
  onResponse: ({response}) => (response._data = response._data?.data),
})

const featuredEventsSplit = computed(() => {
  if (!featuredEvents.value) return []
  const split = []
  const length = featuredEvents.value.length
  const splitLength = Math.ceil(length / featuredEventsSlidersSplitNumber)
  for (let i = 0; i < splitLength; i++) {
    const start = i * featuredEventsSlidersSplitNumber
    const end = start + featuredEventsSlidersSplitNumber
    const splitArray = featuredEvents.value.slice(start, end)
    split.push(splitArray)
  }
  return split
})

const upcomingEventsPage = ref(1)
const showMoreButton = ref(true)

const handleShowMoreButton = (response) => {
  const upcomingEvents = response.data
  const {currentPage, totalPages, pageSize} = response.headerData

  if (currentPage === totalPages) {
    showMoreButton.value = false
  }
  if (Array.isArray(upcomingEvents) && upcomingEvents.length < upcomingEventsToShow) {
    showMoreButton.value = false
  }
  if (!Array.isArray(upcomingEvents) && typeof upcomingEvents === 'object') {
    showMoreButton.value = false
  }
}

const loadMoreUpcomingEvents = async () => {
  upcomingEventsPage.value++
  const response = await eventDataStore.getUpcomingEvents(upcomingEventsPage.value, upcomingEventsToShow)
  const newUpcomingEvents = response.data
  handleShowMoreButton(response)
  upcomingEvents.value = [...upcomingEvents.value, ...newUpcomingEvents]
}

const purchasingStore = usePurchasingStore()
purchasingStore.resetStore()

let debounceTimeout = null

const isFiltering = ref(false)

const onFilterChange = async () => {
  clearTimeout(debounceTimeout)

  debounceTimeout = setTimeout(async () => {
    const upcomingEventsResponse = await eventDataStore.getUpcomingEvents(1, upcomingEventsToShow)
    upcomingEvents.value = upcomingEventsResponse ? upcomingEventsResponse.data : []
    showMoreButton.value = true

    if (Object.keys(eventDataStore.filters).length > 0) {
      isFiltering.value = true
    } else {
      isFiltering.value = false
    }

    handleShowMoreButton(upcomingEventsResponse)
  }, 1000)
}
</script>

<style lang="scss">
h1 {
  color: var(--comp_nu_event_grid-h1-default-text_color);
}
h3 {
  color: var(--comp_nu_event_grid-h3-default-text_color);
}
.featured-events-h5 {
  color: $vanilla-blue;
}
</style>

<template>
  <div>
    <ClientOnly>
      <NuFilter
        :filters="eventFilters"
        v-if="!filtersPending && eventFilters"
        @filter-change="onFilterChange"
      />
    </ClientOnly>
    <section>
      <IndexBannerSet />
    </section>

    <section class="mt-4">
      <div class="d-flex gap-3 flex-column">
        <template v-if="!isFiltering">
          <NuEventGridTitle :title="$t('events.featured_events')" />
          <NuEventSlider
            :events="featuredEventsArray"
            :key="`featured-event-slider-${index}`"
            v-for="(featuredEventsArray, index) in featuredEventsSplit"
          />
        </template>

        <NuEventGridTitle :title="$t('events.upcoming_events')" />
        <NuEventGrid
          :events="upcomingEvents"
          :showMoreButton="showMoreButton"
          v-if="upcomingEvents && upcomingEvents.length > 0"
          @load-more="loadMoreUpcomingEvents"
        />
        <div v-else>{{ $t('events.no_more_events') }}</div>
      </div>
    </section>
  </div>
</template>
