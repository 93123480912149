<script setup>
const props = defineProps({})

const IS_SSR = useNuxtApp().ssrContext
const {isDesktop} = useBreakpoints()

const BANNER_TYPE = Object.freeze({
  URL: 'url',
  VIDEO: 'video',
})

const LOCATIONS = Object.freeze({
  SUPER_HERO_BANNER: 'super_hero_banner',
  HERO_BANNER: 'hero_banner',
  SIDE_UPPER: 'side_upper',
  SIDE_MIDDLE: 'side_middle',
  SIDE_BOTTOM: 'side_bottom',
})

function updateEmbeddedCode(embeddedString, url) {
  if (!embeddedString) return
  const parser = new DOMParser()
  const doc = parser.parseFromString(embeddedString, 'text/html')
  const body = doc.querySelector('body')
  const iframeElement = body.querySelector('iframe')
  const videoElement = body.querySelector('video')

  if (iframeElement) {
    //Setting the attributes and properties for the incoming iframe tag
    iframeElement.setAttribute('width', '100%')
    iframeElement.setAttribute('onclick', `window.open('${url}', '_blank')`)
    const src = iframeElement.getAttribute('src')
    /**
     * Adding autoplay=1 and mute=1 to query params of the src as with
     * these we are able to autoplay the youtube videos on browser
     */
    const updatedSrc = `${src}&mute=1&autoplay=1`
    iframeElement.setAttribute('src', updatedSrc)
  }

  if (videoElement) {
    //Setting the attributes and properties for the incoming video tag
    videoElement.style.width = '100%'
    videoElement.classList.add('embedded-video')
    videoElement.setAttribute('onclick', `window.open('${url}', '_blank')`)
  }
  const updatedEmbeddedCodeString = new XMLSerializer().serializeToString(doc)
  return updatedEmbeddedCodeString
}

const {data: bannersData} = await useAsyncData('banners', async () =>
  $fetch(nuApiUrl('banners', IS_SSR), {
    method: 'POST',
    body: {
      domain: '',
      location: '',
    },
  }),
)

const banners = ref(bannersData.value?.data?.banners)

const homePageBanners = computed(() => {
  const bannersList = banners.value
  return (
    bannersList?.length > 0 &&
    bannersList?.map((banner) => {
      return {
        banner_location: banner.banner_location,
        ...(banner.banner_type === BANNER_TYPE.URL && {image: isDesktop ? banner.desktop : banner.mobile}),
        ...(banner.banner_type === BANNER_TYPE.VIDEO &&
          banner.embedded_code.includes('iframe') && {
            embeddedCode: updateEmbeddedCode(banner.embedded_code, banner.url),
          }),
        url: banner.url,
      }
    })
  )
})

const filteredBanners = computed(() => {
  const locationBannerMap = {}
  if (!homePageBanners.value) return locationBannerMap
  Object.values(LOCATIONS).forEach((location) => {
    locationBannerMap[location] = homePageBanners.value?.filter((banner) => banner.banner_location === location)
  })
  return locationBannerMap
})

const superHeroBanner = computed(() => {
  const banners = filteredBanners.value[LOCATIONS.SUPER_HERO_BANNER]
  return banners?.length > 0 ? [banners?.[0]] : []
})

const showSuperHeroBanner = computed(() => {
  return superHeroBanner.value.length > 0
})

const heroBanners = computed(() => {
  const banners = filteredBanners.value[LOCATIONS.HERO_BANNER]
  return banners?.length > 0 ? banners : []
})

const showHeroBanners = computed(() => {
  return heroBanners.value.length > 0
})

const sideUpperBanner = computed(() => {
  const banners = filteredBanners.value[LOCATIONS.SIDE_UPPER]
  return banners?.length > 0 ? [banners?.[0]] : []
})

const sideMiddleBanner = computed(() => {
  const banners = filteredBanners.value[LOCATIONS.SIDE_MIDDLE]
  return banners?.length > 0 ? [banners?.[0]] : []
})

const sideLowerBanner = computed(() => {
  const banners = filteredBanners.value[LOCATIONS.SIDE_UPPER]
  return banners?.length > 0 ? [banners?.[0]] : []
})

const sideBanners = computed(() => [sideUpperBanner, sideMiddleBanner, sideLowerBanner])
</script>

<style lang="scss">
.banner-set-wrapper {
  .banner-set-grid {
    @include for-non-mobile-only {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: auto;
      gap: 1em 1em;
      grid-auto-flow: row;
      grid-template-areas:
        'homepage-banner homepage-banner homepage-banner homepage-banner'
        'promotion-banner promotion-banner promotion-banner small-banners';
    }

    .nu-carousel {
      margin-bottom: 0;
    }
  }

  .homepage-banner {
    grid-area: homepage-banner;
  }

  .promotion-banner {
    grid-area: promotion-banner;
    height: 297px;

    img {
      object-fit: cover;
      height: 294px;
    }
  }

  .small-banners {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 10px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      'small-banner-1'
      'small-banner-2'
      'small-banner-3';
    grid-area: small-banners;
  }

  .small-banner-1 {
    grid-area: small-banner-1;
  }

  .small-banner-2 {
    grid-area: small-banner-2;
  }

  .small-banner-3 {
    grid-area: small-banner-3;
  }

  .mobile-promotion-banner {
    margin: 0 -12px;
    @include for-non-mobile-only {
      display: none;
    }
    .nu-carousel {
      margin-bottom: 0;
    }
  }
}
</style>

<template>
  <div class="banner-set-wrapper">
    <div class="banner-set-grid">
      <div class="homepage-banner">
        <NuCarousel
          :items="superHeroBanner"
          circle-indicators
          v-if="showSuperHeroBanner"
        />
      </div>
      <div class="promotion-banner">
        <NuCarousel
          :items="heroBanners"
          circle-indicators
          v-if="showHeroBanners"
        />
      </div>
      <div class="small-banners">
        <div
          :class="`small-banner-${index + 1}`"
          :key="`small-promotion-banner-${index}`"
          v-for="(banner, index) in sideBanners"
        >
          <NuCarousel
            :items="banner.value"
            circle-indicators
          />
        </div>
      </div>
    </div>
  </div>
</template>
